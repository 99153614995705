import { z as zod } from "zod";

export const MemberDetailsSchemas = zod.object({
  responseCode: zod.number(),
  description: zod.string(),
  cardNumber: zod.string(),
  traderId: zod.number(),
  traderBalances: zod.object({
    loyaltyBalance: zod.number(),
  }),
  traderDetails: zod.object({
    birthDate: zod.string().nullable(),
    mobileNumber: zod.string().nullable(),
    emailAddress: zod.string().nullable(),
    firstName: zod.string().nullable(),
    lastName: zod.string().nullable(),
    genderId: zod.number().nullable(),
    billingAddress: zod.string().nullable(),
    billingSuburb: zod.string().nullable(),
    billingState: zod.string().nullable(),
    billingPostcode: zod.string().nullable(),
    billingCity: zod.string().nullable(),
    billingBuilding: zod.string().nullable(),
    shippingAddress: zod.string().nullable(),
    shippingSuburb: zod.string().nullable(),
    shippingState: zod.string().nullable(),
    shippingPostcode: zod.string().nullable(),
    shippingCity: zod.string().nullable(),
    shippingBuilding: zod.string().nullable(),
    phoneNumber: zod.string().nullable(),
    company: zod.string().nullable(),
    preferredStore: zod.number().nullable(),
    flEmail: zod.number().nullable(),
    flSMS: zod.number().nullable(),
    debtorCode: zod.string().nullable(),
  }),
});

export const MemberDetailsAPISchemas = MemberDetailsSchemas;
